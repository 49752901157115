* {
    scrollbar-width: thin;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .Chat-Reply {
    height: 38vh !important;
  }
  .Chat-Prompt {
    min-height: 9vh !important;
  }

  .document-generation-page__workspace {
    height: 42vh !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  .Chat-Reply {
    height: 36vh !important;
  }
  .Chat-Prompt {
    min-height: 8vh !important;
  }

  .document-generation-page__workspace {
    height: 40vh !important;
  }
}

@media (-webkit-device-pixel-ratio: 2) {
  .Chat-Reply {
    height: 34vh !important;
  }
  .Chat-Prompt {
    min-height: 7vh !important;
  }

  .document-generation-page__workspace {
    height: 36vh !important;
  }
}

.App {
  text-align: center;
  color: #124559;
  font-family: 'Canvas', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.highlight {
  border: 0.2vmin solid blue !important;
}

.footer {
  display: flex;
  column-gap: 10%;
  position: absolute;
  bottom: 0.3vh;
  font-size: 1.3vh;
  color: black;
  left: 45%;
}

.page-container {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
}

.contact-page__header {
  font-size: 2.4vmin;
  float: left;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-left: 5%;
  margin-top: 6%;
}

.contact-page__text {
  font-size: 1.8vmin;
  float: left;
  text-align: left;
  width: 100%;
  margin-bottom: 3%;
}

.contact-page__content {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 5%;
  padding-top: 2%;
  margin-bottom: 3%;
}

.contact-page__mailto-button {
  background-color: #aec3b0;
  display: flex;
  justify-content: space-between;
  color: #124559;
  border: none;
  width: fit-content;
  border-radius: 2.4vmin;
  text-decoration: none;
  padding: 2% 8%;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 2.2vmin;
  text-align: center;
}

.page-content {
  width: 94%;
  padding-bottom: 2%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #124559;
  height: 100vh;
  width: 6%;
}

.navbar__item {
  margin-top: 5vh;
}

.navbar__item--leading {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.navbar__item--last {
  margin-top: 10vh;
}

.help-page-header {
  font-size: 2.4vmin;
  float: left;
  text-align: left;
  width: 100%;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 3;
}


.transcriptGradientTop {
  width: 95%;
	height: 3%;
	position:absolute;
  z-index: 1;
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.transcriptGradientBottom {
	width: 95%;
	height: 10%;
  bottom: 0;
	position:absolute;
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 170%);
}

.faq-list-wrapper {
  width: 95%;
  padding-top: 2%;
  height: 78vh;
  overflow-y: scroll;
}

.faq-list {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 10%;
  display: inline-block;
  position: relative;
  list-style-type: none;
  padding-left: 5%;
  text-align: left;
  height: 180vh;
}


li.faq-list-item:not(:last-child) { 
  margin-bottom: 3%;
  width: 70%;
}

.Chat {
  display: flex;
  flex-direction: column;
}

.Chat-action-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-left: 4%;
  align-items: center;
}

.chat-version-switch {
  background-color: #fff;
}

.chat-version-button {
  border: none;
  background-color: #124559;
  color: #fff;
  padding: 1vmin 1.6vmin;
  border-radius: 3vmin;
}

.chat-version-button--active {
  border-radius: 3vmin;
  padding: 0.2vmin 0.4vmin;
}

.chat-status-indication {
  font-size: 1.2vmin;
  float: left;
  margin-left: 5%;
  margin-bottom: 0.5%;
}
.new-chat-button {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  color: #124559;
  border: none;
  padding: 1vmin 1.6vmin;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 2.2vmin;
  width: 9em;
}

.conversation-select {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: 6%;
}

.conversation-list {
  border: 0.2vmin solid #aec3b0;
  margin-top: 8%;
  position: absolute;
  list-style-type: none;
  max-height: 40vh;
  overflow-y: scroll;
  z-index: 1;
  background-color: #fff;
  color: #124559;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 1.5vmin;
  right: 5%;
}

.Chat-Reply {
  height: 40vh;
  text-align: left;
  padding: 3%;
  overflow-y: scroll;
  overflow-x: clip;
}

.chat-window {
  border: 0.2vmin solid #aec3b0;
  width: 90%;
  margin-left: 4%;
  margin-top: 0.5%;
  margin-right: auto;
  border-radius: 3vmin;
}

.Chat-Prompt__input {
  color: #124559;
  background-color: #fff;
  border: none;
  border-radius: 4.5vmin;
  padding-left: 3%;
  margin-right: auto;
  text-align: left;
  line-break: normal;
  overflow: hidden;
  width: 100%;
  font-size: 2vh;
  resize: none;
}
.Login {
  display: flex;
  flex-direction: column;

  &__password-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1vw;
  }
  
  &__show-password {
    cursor: pointer;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 2%;
    margin-top: 1%;
  }
  
  &__greeting {
    &--start {
      text-align: left;
      margin-left: 35%;
      margin-top: 10%;
    }
    &--end {
      margin-left: 35%;
      text-align: left;
      margin-bottom: 5%;
    }
  }
  
  &__request-password-reset {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    margin-left: 35%;
    width: 25%;
    margin-top: 0.5%;
    font-size: x-small;
  }

  &__forgot-password {
    margin-top: 10%;
    font-size: 3.4vh;
    font-weight: bold;
    width: 25%;
    margin-left: 35%;
  }

  &__new-password {
    font-size: 2.8vh;
    margin-top: 10%;
    font-weight: bold;
    width: 25%;
    margin-left: 35%;
    margin-bottom: 3%;
  }

  &__text {
    padding: 1%;
    width: 25%;
    margin-left: 35%;
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  &__title {
    font-size: 3.4vmin;
    margin-left: 5%;
    font-weight: bold;
  }
  
  &__input {
    margin-top: 0.5%;
    font-size: 2vmin;
    display: flex;
    flex-direction: row;
    border: 0.1vmin solid #99b393;
    height: 8vh;
    border-radius: 3.5vmin;
    padding-left: 3%;
    padding-right: 3%;
    width: 19%;
    margin-left: 35%;
    text-align: left;
    justify-content: left;
    text-align: center;
  }
  
  &__button {
    background-color: #99b393;
    color: #124559;
    width: 25%;
    border-radius: 3vmin;
    margin-top: 2%;
    height: 6vh;
    margin-left: 35%;
    float: left;
    font-size: large;
  }
}
  
  .logout:hover {
    cursor: pointer;
  }

.Chat-Prompt__input:focus {
  outline: none;
}

.arrow-button {
  margin-right: 3%;
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  background-color: #fff;
  padding: 1vmin 1.6vmin;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 1.4vmin;
  border: none;
}

.arrow-button > .arrow {
  width: 0.6vmin;
  height: 0.6vmin;
  border-right: 0.3vmin solid #153b2d;
  border-bottom: 0.3vmin solid #153b2d;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 0.6vmin;
  transition: all .3s ease;
}

.arrow-button > .arrow::before {
  display: block;
  background-color: #fff;
  width: 0.3vmin;
  transform-origin: bottom right;
  height: 0.2vmin;
  position: absolute;
  opacity: 0;
  bottom: calc(-0.2vmin / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-button:hover > .arrow {
  transform: rotate(-45deg) translate(0.4vmin, 0.4vmin);
  border-color: #124559;
}

.arrow-button:hover > .arrow::before {
  opacity: 1;
  width: 1.2vmin;
}

.arrow-button:hover {
  background-color: #fff;
  color: #124559;
}

.chevron { 
  display: inline-block; 
  border-right: 
  0.2vmin solid #124559; 
  border-bottom: 
  0.2vmin solid #124559; 
  width: 0.5vmin; 
  height: 0.5vmin; 
} 

.chevron-down { 
  transform: rotate(45deg);
  margin-bottom: 2%;
} 

.chevron-up { 
  transform: rotate(-135deg); 
} 

.Chat-Prompt {
  border: 0.2vmin solid #aec3b0;
  width: 88%;
  margin-left: 4%;
  min-height: 10vh;
  margin-top: 3%;
  margin-right: auto;
  border-radius: 3vmin;
  display: flex;
  flex-direction: row;
  padding: 1%;
}

.chatResponse {
  display: flex;
  flex-direction: row;
  border-radius: 2.4vmin;
  margin-bottom: 4%;
  margin-top: 1%;
  width: 90%;
}

.chatResponse__reply {
  background-color: #aec3b019;
  padding: 2%;
  border-radius: 2.4vmin;
}

.chatResponse__reply h1 {
  margin-top: 0;
}

.chatResponse > p {
  margin-top: 1%;
}

.chatResponse__icon {
  margin-right: 1%;
}


.chatRequest {
  display: flex;
  flex-direction: row;
  margin-bottom: 4%;
  border-radius: 1.2vmin;
}

.chatRequest__prompt {
  font-size: 2vmin;
  line-height: 3vmin;
  margin-left: auto;
  background-color: #aec3b053;
  padding: 1%;
  border-radius: 1.2vmin;
}

.chatRequest__icon {
  margin-left: 1%;
}

.sender {
  margin-bottom: 1%;
}

.Chat-header {
  display: flex;
  width: 50%;
  margin-left: 5%;
  margin-top: 3.5%;
  font-size: 4vmin;
  text-align: left;
  align-items: center;
}


.Chat-disclaimer{
  width: 50%;
  margin-left: 25%;
  margin-top: 1%;
  font-size: 1.4vmin;
  color: #aec3b0;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #124559;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info-icon {
  position: absolute;
  /* position in the lower right corner */
  bottom: 4%;
  right: 7%;
}


/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fff;
  color: #124559;
  text-align: left;
  padding: 1vmin 0.5vmin 1vmin 1.5vmin;
  border-radius: 0.6vmin;
  width: 20vw;
  line-break: break-word;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -15vw;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 4.5vw;
  border-width: 0.5vmin;
  border-style: solid;
  border-color: #124559;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.rating-text {
  font-size: 1.2vmin;
  width: 10vw;
  text-align: left;
}

.comment-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 4%;
}

.comment-box__input {
  color: #124559;
  border: none;
  height: 6vh;
  border-radius: 2.5vmin;
  text-align: left;
  width: 90%;
  margin-top: 2%;
  font-size: 1.6vmin;
  font-family: 'Canvas', sans-serif;
  padding: 3%;
  background-color: #12455930;
}

.comment-box__input:focus {
  outline: none;
  border: 0.1vmin solid #124559;

}

.submit-comment {
  margin-top: 2%;
  color: black;
  background-color: #12455945;
  border: none;
  width: 15%;
  border-radius: 1.5vmin;
  height: 7vh;
  font-size: 1.6vmin;
}

.submit-comment:hover {
  color: black;
  background-color: #12455916;
  cursor: pointer;
}

.dot-flashing {
  margin-left: 4%;
  position: relative;
  width: 1vmin;
  height: 1vmin;
  border-radius: 0.5vmin;
  background-color: #fff;
  color: black;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.loading-dots {
  margin-left: 2%;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -1.5vmin;
  width: 1vmin;
  height: 1vmin;
  border-radius: 0.5vmin;
  background-color: #fff;
  color: black;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 1.5vmin;
  width: 1vmin;
  height: 1vmin;
  border-radius: 0.5vmin;
  background-color: #fff;
  color: black;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: black;
  }
  50%, 100% {
    background-color: rgba(136, 135, 141, 0.2);
  }
}

.ml-3-5 {
  margin-left: 3.5%;
}

.ml-5-em {
  margin-left: 5em;
}


.conversation-select-button {
  display: flex;
  background-color: #fff;
  color: #124559;
  border-radius: 4vmin;
  padding: 1vmin 1.6vmin;
  transition: all .3s ease;
  border: 0.1vmin solid #124559;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 1.4vmin;
}

.conversation {
  cursor: pointer;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  height: 1.3rem;
  font-family: 'Canvas', sans-serif;
  font-size: 1.6vmin;
  text-align: left;
}

.conversation:hover {
  background-color: #fff;
} 

.rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 27%;
}

.copy {
  width: 15%;
}

.copy:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.copy-text {
  padding: 10%;
  border-radius: 1.5vmin;
  border: 0.1vmin solid #124559;
}


textarea::-webkit-input-placeholder {
  font-family: 'Canvas', sans-serif;
}

textarea:-ms-input-placeholder {
  font-family: 'Canvas', sans-serif;
}

textarea:-moz-placeholder {
  font-family: 'Canvas', sans-serif;
}

textarea::-moz-placeholder {
  font-family: 'Canvas', sans-serif;
}

.document-generation-page {
  display: flex;
  flex-direction: row;
  margin-left: 5%
}

.document-generation-page__content {
  flex: 7 1 auto;
  margin-right: 2%;
  width: min-content;
}
.document-generation-page__sidebar {
  flex: 2 1 auto;
  width: min-content;
  border-left: 0.1vmin solid grey;
  ul {
    list-style-type: none;
    margin-left: 0;
    padding: 0;
  }
  text-align: left;
  padding-left: 2%;
}

.document-generation-page__workspace {
  border: 0.2vmin solid rgb(172, 190, 172);
  border-radius: 5vmin;
  background-color: #ececec;
  height: 45vh;
  margin-top: 2%;
  overflow-y: scroll;
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
}

.document-generation-page__prompt-container {
  border: 0.2vmin solid rgb(172, 190, 172);
  border-radius: 5vmin;
  margin-top: 3%;
  background-color: #ececec;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 5%;
  padding-top: 5%;
  align-items: center;
  display: flex;
}

.document-generation-page__header {
  text-align: left;
  font-size: larger;
  margin-top: 4%;
  font-weight: 510;
}

textarea {
  resize: none;
  outline: none;
  border: none;
}

.document-generation-page__prompt-input {
  border: none;
  font-size: 2vmin;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  background-color: inherit;
  width: 70%;
}

.document-generation-page__generate-button {
  margin-left: 10%;
  margin-right: 2%;
  background-color: inherit;
  border: none;
  font-size: 4vmin;
  font-weight: 100;
}

.document-generation-page__disclaimer {
  margin-top: 1%;
  font-size: 1.4vmin;
  color: #aec3b0;
}

.document-generation-page__choice-container {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  margin-top: 3%;
}

.document-generation-page__choice-prompt-container {
  border: 0.2vmin solid rgb(172, 190, 172);
  border-radius: 5vmin;
  display: flex;
  padding-top: 1%;
  padding-bottom: 1%;
  align-items: center;
  padding-left: 4%;
  background-color: #ececec;
}

.document-generation-page__choice-prompt {
  width: 70%;
  font-size: 2vmin;
  text-align: left;
  background-color: inherit;
}

.document-generation-page__new-document-container {
  border-radius: 5vmin;
  display: flex;
  padding-top: 1%;
  padding-bottom: 1%;
  align-items: center;
  background-color: #99b393;
  padding-left: 4%;
  cursor: pointer;
}

.document-generation-page__new-document-text {
  margin-right: 50%;
  font-size: 1.8vmin;
  font-weight: bold;

}

.document-generation-page__choice-regenerate-button {
  margin-left: 3%;
  margin-right: 2%;
  background-color: inherit;
  border: none;
  font-size: 4vmin;
  font-weight: 100;
}

.document-generation-page__loading-container {
  display: flex;
  margin-top: 3%;
  font-size: 2vmin;
  align-items: baseline;
}

.document-generation-page__header {
  display: flex;
}

.document-generation-page__header-symbols {
  margin-left: auto;
  margin-right: 4%;
  display: flex;
  column-gap: 0.3vw;
  cursor: pointer;
  position: relative;
}

.absolute-0 {
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.document-generation-page__sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}
.pagination__page {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  cursor: pointer;
  font-size: 1.5vmin;
  color: #124559;
}

.pagination__button {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  cursor: pointer;
  font-size: 1.2vmin;
  color: #124559;
  border-radius: 1vmin;
  padding: 0.5vmin;
  border: 0.1vmin solid #124559;
  background-color: #fff;
}

.pagination__button:hover:not([disabled]) {
  color: #fff;
  background-color: #124559;
}

.error-banner {
  background-color: rgb(196, 29, 29);
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: none;
}

.success-banner {
  background-color: rgb(29, 118, 29);
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: none;
}

.filesPage {
  margin-left: 5%;
  width: 85%;


  &__dropzone {
    min-height: 50vh;
  }
  &__table-wrapper {
    width: 100%;
    overflow: scroll;
    max-height: 70vh;
  }

  &__title {
      font-size: 1.2rem;
      font-weight: 550;
      margin-bottom: 4%;
      margin-top: 4%;
      text-align: left;
  }

  &__table-topheader {
    top: 0;
    z-index: 2;
    background-color: #fff;
    position: sticky;
  }
  &__table-topheader-item {
    font-weight: 600;
    color: #124559;
    padding-bottom: 1%;
    font-size: 1.3rem;
    &--right {
      margin-left: auto;
      margin-right: 0;
      padding-bottom: 1%;
    }
  }

  &__status {
    cursor: pointer;
    display: flex;
    gap: 1vmin;
    padding-left: 1vw;
  }

  &__search {
    float: right;
    border-radius: 5vmin;
    padding: 3%;
    padding-left: 10%;
    font-weight: 600;
  }

  &__table-header {
    font-weight: bold;
    top: 6%;
    z-index: 2;
    background-color: #fff;
    position: sticky;
  }

  &__table-header-item {
    border-top: 0.3vmin solid rgb(224, 233, 224);
    text-align: left;
    padding-top: 1%;
    padding-right: 20%;
    padding-bottom: 0.5%;
    border-right: 0.3vmin solid rgb(224, 233, 224);
    &--center {
      white-space: nowrap;
      padding-left: 1%;
    }

    &--right {
      border-top: 0.3vmin solid rgb(224, 233, 224);
    }
  }

  &__table {
      width: 100%;
      margin-top: 0.5rem;
      max-height: 60vh;
      overflow: scroll;
  }

  &__table-item {
      align-items: center;
  }
}

.file-upload-button {
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  float: left;
}

.file {
  align-items: center;
  padding: 0.75rem;

  &__name {
      color: #374151;
      font-size: 1.1rem;
      text-align: left;
      max-width: 30vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
  }

  &__date {
      font-size: 1.1rem;
      color: #6b7280;
      margin-left: 1rem;
      text-align: left;
      padding-left: 1%;
  }

  &__status {
      display: flex;
      align-items: center;
      font-size: 1.1rem;
      color: #374151;
      padding-left: 1%;
      margin-left: 1rem;
      text-align: left;

      svg {
          margin-left: 0.5rem;
      }
  }

  &__actions {
  }

  &__open-pdf-button,
  &__download-docx-button,
  &__delete-button {
    float: right;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #4b5563;
    margin-left: 2rem;

    &:hover {
        color: #1f2937;
    }
  }
}

.p-2 {
  padding: 0.5rem;
}

.fl {
  float: left;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #d1d5db;
}

.rounded-md {
  border-radius: 0.375rem;
}

.menu {
  position: absolute;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0 , .1);
  list-style-type: none;
  margin: 5px 0;
  padding: 5px;
  border-radius: 24px;
  width: 150px;
  background-color: white;
  max-height: 50vh;
  overflow: scroll;
  z-index: 1;
}

.menu > li {
  margin: 0;
  border-radius: inherit;
  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.teams-page {
  text-align: left;
  margin-top: 8%;
  margin-left: 8%;

  &__invite-button {
    color: white;
    background-color: #aec3b0;
    padding: 1.5%;
    border-radius: 1vmin;
    border: none;
    margin-top: 3vh;
    cursor: pointer;
  }

  &__licenses-title {
    font-weight: bold;
  }

  &__licenses-box {
    margin-top: 2%;
    padding: 2%;
    background-color: rgb(221, 226, 221);
    border-radius: 1vmin;
    width: 40%;
  }

  &__members {
    margin-top: 2%;
    max-height: 40vh;
    overflow-y: scroll;
  }

  &__members-table {
    border-collapse: collapse;
  }

  &__members-table-head {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }

  &__members-table-header {
    padding-bottom: 2%;
  }
  &__members-table-head-row {
    border-bottom: 0.3vh solid rgb(221, 226, 221);
  }

  &__members-table-row {
    border-bottom: 0.3vh solid rgb(221, 226, 221);
  }
  
  &__members-table-cell {
    padding: 2% 0 2% 0;
    &--starting {
      min-width: 25vw;
    }
    &--center {
      width: 12vw;
    }
  }
}

.invite-form {
  width: 50vw;
  display: flex;
  flex-direction: column;
  &__title {
    font-size: xx-large;
    text-align: center;
    font-weight: bold;
    margin-top: 5%;
  }

  &__email {
    font-size: 2.3vmin;
    padding: 4%;
    letter-spacing: 0.15vw;
    border-radius: 4vmin;
    width: 50%;
    margin-left: 20%;
    margin-top: 10%;
    text-align: center;
  }

  &__submit {
    font-size: 2.3vmin;
    width: 58%;
    margin-left: 20%;
    border: none;
    background-color: #99b393;
    color: #124559;
    font-weight: bold;
    cursor: pointer;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 3%;
    border-radius: 4vmin;
    margin-bottom: 6%;
  }
}

.modal-content {
  width: fit-content !important;
}

.danger-modal {
  font-size: larger; 
  width: 30vw;
  padding: 3%;
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: x-large;
    padding-bottom: 1%;
    width: 95%;
    border-bottom: 0.3vmin solid rgb(221, 226, 221);
  }
  &__text {
    margin-top: 4%;
    margin-bottom: 8%;
    margin-left: 1%;
  }
  &__cancel {
    border: none;
    background-color: #aec3b0;
    color: white;
    padding: 1.5%;
    width: 5vw;
  }
  &__submit {
    margin-right: 5%;
    float: right;
    width: 5vw;
    color: white;
    padding: 1.5%;
    border: none;
    background-color: rgb(214, 88, 29);
  }
}